<template>
  <div id="login">
    <el-form ref="form" :model="form">
      <el-form-item label="">
        <h1>管理员密码登录</h1>
      </el-form-item>
      <el-form-item label="">
        <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-user"
          v-model="form.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input
          placeholder="请输入密码"
          auto-complete="new-password"
          prefix-icon="el-icon-s-goods"
          v-model="form.pass"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input
          placeholder="请输入验证码"
          v-model="form.verify"
          style="width: 190px; margin-right: 5px; vertical-align: middle"
        ></el-input>
        <canvas id="myCanvas" width="100" height="38" @click="onVerifi">
          您的浏览器不支持 HTML5 canvas 标签。
        </canvas>
      </el-form-item>
      <el-form-item label="" style="margin-bottom:50px">
        <el-button type="primary" @click="onSubmit">登录</el-button>
      </el-form-item>
      <el-form-item label="" style="display: none">
        <el-button type="primary" @click="onRegist">注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      form: {
        name: "",
        pass: "",
        verify: "", //输入的验证码
        reVerify: "", //后台生成的验证码
      },
    };
  },
  created() {
    var that = this;
    document.onkeydown = function () {
      var key = window.event.keyCode;
      if (key == 13) {
        that.onSubmit();
      }
    }; //点击enter,登录
  },
  methods: {
    setCookie: function (cname, cvalue) {
      var d = new Date();
      d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    onSubmit: function () {
      var that = this;
      let postData = new URLSearchParams();
      postData.append("userName", this.form.name);
      postData.append("passWord", this.form.pass);
      postData.append("ver", this.form.verify.toLowerCase());
      this.$axios
        .post(this.baseUrl + "admin/index/login", postData)
        .then((response) => {
          if (response.data.code != "200") {
            that.$alert(response.data.msg, "提示", {
              confirmButtonText: "确定",
            });
          } else {
            that.setCookie("touBiaoVerify", response.data);
            that.$router.push("/admins/index");
          }
        })
        .catch(function (error) {
          window.console.log(error);
        }); //ajax请求失败
    },
    onRegist: function () {},
    randomColor: function () {
      /*
			var r = Math.floor(Math.random()); * 256);
			var g = Math.floor(Math.random() * 256);
			var b = Math.floor(Math.random() * 256);
			return "rgb(" + r + "," + g + "," + b + ")";
			*/
      var randomColor = ["#45B9F1", "#F18145", "#1C8245"];
      var index = Math.floor(Math.random() * 3);
      return randomColor[index];
    },
    onVerifi: async function () {
      await this.$axios
        .post(this.baseUrl + "admin/index/createVer")
        .then((response) => {
          this.form.reVerify = response.data;
        })
        .catch(function (error) {
          window.console.log(error);
        });

      var canvas_width = 100;
      var canvas_height = 40;
      var c = document.getElementById("myCanvas");
      var context = c.getContext("2d");
      context.clearRect(0, 0, canvas_width, canvas_height);
      context.fillText(txt, 0, 0);

      for (var i = 0; i < 4; i++) {
        //这里的for循环可以控制验证码位数（如果想显示6位数，4改成6即可）
        var deg = Math.random() - 0.5; //产生一个随机弧度
        var txt = this.form.reVerify.substring(i, i + 1); //得到随机的一个内容
        var x = 10 + i * 20; //文字在canvas上的x坐标
        var y = 20 + Math.random() * 8; //文字在canvas上的y坐标
        context.font = "bold 23px 微软雅黑";
        context.translate(x, y);
        context.rotate(deg);
        context.fillStyle = this.randomColor();
        context.fillText(txt, 0, 0);
        context.rotate(-deg);
        context.translate(-x, -y);
      }
      for (i = 0; i <= 5; i++) {
        //验证码上显示线条
        context.strokeStyle = this.randomColor();
        context.beginPath();
        context.moveTo(
          Math.random() * canvas_width,
          Math.random() * canvas_height
        );
        context.lineTo(
          Math.random() * canvas_width,
          Math.random() * canvas_height
        );
        context.stroke();
      }
      for (i = 0; i <= 30; i++) {
        //验证码上显示小点
        context.strokeStyle = this.randomColor();
        context.beginPath();
        x = Math.random() * canvas_width;
        y = Math.random() * canvas_height;
        context.moveTo(x, y);
        context.lineTo(x + 1, y + 1);
        context.stroke();
      }
    },
  },
  mounted: function () {
    this.onVerifi();
  },
};
</script>

<style scoped>
#login {
  background-image:linear-gradient(to bottom right,#079BD7,#0066B1,#004D98);
  height: 100%;
}
.el-form {
  width: 480px;
  position: fixed;
  top: 40%;
  left: 50%;
  margin-left: -240px;
  margin-top: -160px;
  background: white;
  border-radius: 20px;
  animation: right 1s;
  transition: all 0.3s;
  animation-timing-function: cubic-bezier(1, 1, 0.5, 0.1);
  animation-fill-mode: both;
  box-shadow: 0px 0px 20px grey;
}
.el-form /deep/ .el-form-item {
  /* background-color:transparent;
		border:1px solid rgba(255, 255, 255, 0.1);
		color:#fff; */
  width: 300px;
  margin: 0 auto;
  margin-top:20px
}
.el-form .el-button--primary {
  width: 100%;
}
.el-form h1 {
  text-align: center;
  color: black;
  letter-spacing: 3px;
}
.el-form #myCanvas {
  background-color: #fff;
  vertical-align: middle;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
</style>
